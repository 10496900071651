/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import './debug.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import App from './components/App.jsx';

ReactDOM.render(
    <HelmetProvider>
        <React.StrictMode>
            <Helmet></Helmet>
            <div className="flex flex-col bg-custom-pattern min-h-screen">
                <App />
            </div>
        </React.StrictMode>
    </HelmetProvider>,
    document.getElementById('root'),
);

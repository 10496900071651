/* eslint-disable no-unused-vars */
import { Link } from 'react-router-dom';

const Header = (props) => (
    <header className="w-full">
        <nav
            className={
                props.selected === 0
                    ? 'flex justify-end bg space-x-4 p-4 text-gray-200'
                    : 'flex justify-end bg space-x-4 p-4 text-gray-900 sm:text-gray-200 bg-white sm:bg-transparent'
            }
        >
            <Link
                to="/"
                className={
                    props.selected === 0
                        ? ' border-b-2 border-green-400 font-bold'
                        : ''
                }
            >
                Home
            </Link>

            <Link
                to="/about"
                className={
                    props.selected === 1
                        ? ' border-b-2 border-green-400 font-bold'
                        : ''
                }
            >
                About
            </Link>
        </nav>
    </header>
);
export default Header;

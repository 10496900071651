/* eslint-disable no-unused-vars */
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';

import { useState } from 'react';
import Header from './Header';
// import Footer from './Footer';
import Home from '../routes/Home';
import About from '../routes/About';
// import Contact from '../routes/Contact';
// import NotFound from '../routes/NotFound';

const App = () => {
    const [selected, setSelected] = useState(0);

    return (
        <Router>
            <Header selected={selected} />
            <Switch>
                {/* <Route exact path="/" component={Home} /> */}

                <Route
                    path="/about"
                    render={(props) => (
                        <About
                            {...props}
                            changeSelected={setSelected}
                        />
                    )}
                />

                <Route
                    path="/*"
                    render={(props) => (
                        <Home
                            {...props}
                            changeSelected={setSelected}
                        />
                    )}
                />

                {/* <Route path="/about" component={About} /> */}
                {/* <Route path="/contact" component={Contact} /> */}
                {/* <Route path="/*" component={NotFound} /> */}
            </Switch>
            {/* <Footer /> */}
        </Router>
    );
};

export default App;

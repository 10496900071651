/* eslint-disable no-unused-vars */
import { faCube } from '@fortawesome/free-solid-svg-icons';
import {
    faGithub,
    faLinkedin,
    faDiscord,
    faReddit,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Home = (props) => {
    useEffect(() => {
        document.title = 'Home';
        props.changeSelected(0);
    });

    return (
        <main className="flex text-gray-200 font-custom p-5 justify-center items-center flex-grow">
            <div className="flex flex-col w-screen sm:w-6/12 md:w-5/12 lg:w-4/12 min-w-min">
                {/* title */}
                <h1 className="text-6xl mobile:text-5xl">
                    Hi there!
                </h1>

                {/* intro */}
                <div className="text-xl">
                    <p className="py-5 text-3xl mobile:text-2xl">
                        My name is{' '}
                        <span className="border-b-2 border-green-400">
                            Felipe Almeida
                        </span>
                        , and I am a{' '}
                        <span className="font-bold">
                            Full Stack Web Developer
                        </span>{' '}
                        currently living in{' '}
                        <span className="text-red-500">
                            London, Canada
                        </span>
                        .
                    </p>

                    <p className="my-5 hidden sm:block">
                        Below you can learn about myself, or if you
                        are interested in contacting me you can send
                        an email to{' '}
                        <a
                            className="font-bold"
                            href="mailto:felipera.jobs@gmail.com"
                        >
                            felipera.jobs@gmail.com
                        </a>{' '}
                        and I'll be sure to reply as fast as I can.
                    </p>
                </div>

                {/* pages */}
                <div className="flex flex-row justify-between mt-2 mb-6">
                    <Link
                        to="/about"
                        className="
                        transition duration-200 ease-in-out
                        bg-transparent  border-indigo-400 text-indigo-400
                        transform hover:scale-110
                        rounded-lg border text-lg py-1 px-5
                        "
                    >
                        About me
                    </Link>
                    {/* 
                    <button class="transition duration-500 ease-in-out bg-blue-600 hover:bg-red-600 transform hover:-translate-y-1 hover:scale-110 ...">
                        Hover me
                    </button> */}

                    {/* <a
                    href="#"
                    className="bg-transparent rounded-lg border border-red-400 text-lg text-red-400 py-1 px-5"
                >
                    Contact
                </a> */}
                </div>

                {/* social medias */}
                <div className="flex justify-around text-lg w-40 text-gray-500">
                    <a
                        href="https://github.com/franbeep"
                        className="transition duration-200 ease-in-out hover:text-gray-200"
                    >
                        <span alt="github">
                            <FontAwesomeIcon icon={faGithub} />
                        </span>
                    </a>
                    <a
                        href="https://www.linkedin.com/in/feliperadev/"
                        className="transition duration-200 ease-in-out hover:text-gray-200"
                    >
                        <span alt="linkedin">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </span>
                    </a>
                    <a
                        href="https://discord.gg/sU9m9mAV"
                        className="transition duration-200 ease-in-out hover:text-gray-200"
                    >
                        <span alt="discord">
                            <FontAwesomeIcon icon={faDiscord} />
                        </span>
                    </a>
                    <a
                        href="https://www.reddit.com/user/lolcatme"
                        className="transition duration-200 ease-in-out hover:text-gray-200"
                    >
                        <span alt="reddit">
                            <FontAwesomeIcon icon={faReddit} />
                        </span>
                    </a>
                    <a
                        href="https://sketchfab.com/lolcatme"
                        className="transition duration-200 ease-in-out hover:text-gray-200"
                    >
                        <span alt="sketchfab">
                            <FontAwesomeIcon icon={faCube} />
                        </span>
                    </a>
                </div>
            </div>
        </main>
    );
};

export default Home;

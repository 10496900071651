import { useEffect } from 'react';
import Photo from '../static/photo.jpg';

const About = (props) => {
    useEffect(() => {
        document.title = 'About';
        props.changeSelected(1);
    });

    return (
        <main className="flex flex-grow bg-custom-pattern text-gray-800 font-custom sm:p-5 justify-center items-center">
            <div className="flex flex-col lg:mt-0 sm:mt-16 w-screen lg:w-1/2 min-w-min bg-white sm:rounded-xl p-8">
                {/* title */}
                <h1 className="text-6xl mb-3">So...</h1>

                <div className="min-w-full container items-center">
                    <img
                        className="w-32 mobile:w-24 object-cover rounded-xl float-right m-2 mobile:m-4"
                        src={Photo}
                        alt="Felipe's Photo"
                    ></img>
                    <p className="text-xl mb-3">
                        Hi there (again, possibly)! As you may know,
                        I'm Felipe Almeida. I'm a passionate and
                        enthusiastic developer, having worked for more
                        than 2 years as such. I have worked with many
                        programming technologies throughout my career,
                        including mostly but not limited to web
                        related ones.
                    </p>

                    <p className="text-xl mb-3">
                        At the present, I tend to use a more
                        JavaScript-based stack, following its new
                        'trend' (and as a personal choice too). Here
                        is a list of a few languages, frameworks and
                        technologies that I have used through my life,
                        and feel that I'm confident at using in a
                        professional environment:
                    </p>
                </div>

                <div className="flex justify-between sm:my-3 sm:px-3">
                    <div className="sm:w-1/2">
                        <ul className="list-disc">
                            <li>JavaScript</li>
                            <li>Python</li>
                            <li>ReactJS</li>
                            <li>NodeJS</li>
                            <li>ExpressJS</li>
                            <li>Django</li>
                            <li>CSS Frameworks (Tailwind, etc.)</li>
                            <li>Responsive Design</li>
                            <li>Testing (Jest)</li>
                            <li>Versioning (Git/SVN)</li>
                        </ul>
                    </div>
                    <div className="sm:w-1/2">
                        <ul className="list-disc">
                            <li>UNIX Environments</li>
                            <li>Shell Programming</li>
                            <li>GLSL/HLSL (Graphics Programming)</li>
                            <li>C# Language</li>
                            <li>Java</li>
                            <li>Containerization (Docker)</li>
                            <li>SQL-based DBs</li>
                        </ul>
                    </div>
                </div>

                <p className="text-xl my-6">
                    Feel free to contact me through my{' '}
                    <a
                        className="text-blue-400 underline"
                        href="mailto:felipera.jobs@gmail.com"
                    >
                        email
                    </a>{' '}
                    or any social at any time, I'm always up to do
                    something creative or exciting! <br />
                    Thanks.
                </p>
            </div>
        </main>
    );
};

export default About;
